import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import InputComponent from "../../../common/form/input";
import ToggleButton from "../../../common/form/toggleButton";

const SignupForm = (props) => {
  const logo = props.logo;

  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const decrypt = (salt, encoded) => {
    if (encoded) {
      const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
      const applySaltToChar = (code) =>
        textToChars(salt).reduce((a, b) => a ^ b, code);
      return encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("");
    }
  };

  useEffect(() => {
    setEmail(decrypt("email", localStorage.getItem("email")));
    setPassword(decrypt("password", localStorage.getItem("password")));
    setFullName(decrypt("fullName", localStorage.getItem("fullName")));
  }, []);

  return (
    <Formik
      initialValues={{
        fullName: fullName,
        email: email,
        password: password,
        appType: "testRoutePro",
      }}
      enableReinitialize
      onSubmit={props.signup}
      validationSchema={ValidationSchema}
    >
      {({ handleSubmit, values }) => (
        <form
          onSubmit={handleSubmit}
          className="signup-form d-flex justify-content-between flex-column"
        >
          <div className="form-item">
            <Field name="fullName" type="text" value={values.fullName}>
              {(props) => (
                <InputComponent
                  {...props}
                  name="fullName"
                  type="text"
                  placeholder="Full Name"
                />
              )}
            </Field>
            <ErrorMessage component="span" className="error" name="fullName" />
          </div>
          <div className="form-item">
            <Field name="email" type="text" value={values.email}>
              {(props) => (
                <InputComponent
                  {...props}
                  name="email"
                  type="text"
                  placeholder="E-mail"
                />
              )}
            </Field>
            <ErrorMessage component="span" className="error" name="email" />
          </div>
          {/* <div className='form-item'>
                            <Field 
                                name='contactNumber'
                                type='text'
                                value={values.contactNumber}
                            >
                                {props => (<InputComponent 
                                        {...props}
                                        name='contactNumber'
                                        type='text'
                                        placeholder='Contact Number'
                                    />)}
                            </Field>
                            <ErrorMessage component='span' className='error' name='contactNumber' />
                        </div> */}
          <div className="form-item">
            <Field name="password" type="password" value={values.password}>
              {(props) => (
                <InputComponent
                  name="password"
                  {...props}
                  type="password"
                  placeholder="Password"
                />
              )}
            </Field>
            <ErrorMessage component="span" className="error" name="password" />
          </div>
          {/* <Field name="appType" type="text" value={values.appType}>
            {(props) => (
              <ToggleButton
                name="appType"
                type="text"
                title={"Sign up me as a"}
                logo={logo}
                {...props}
              />
            )}
          </Field> */}
          <button
            disabled={props.loading}
            type="submit"
            className="button button--primary button-full-width"
          >
            Sign up
          </button>
        </form>
      )}
    </Formik>
  );
};

const ValidationSchema = Yup.object({
  fullName: Yup.string().required("Please eneter Full Name"),
  email: Yup.string()
    .email("Please enter valid email id")
    .required("Please enter email"),
  password: Yup.string().required("Please enter password"),
});

export default SignupForm;
