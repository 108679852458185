import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import InputComponent from "../../../common/form/input";
import ToggleButton from "../../../common/form/toggleButton";
import { getAppMode } from "../../../common/authService";
const LoginForm = (props) => {
  const logo = props.logo;

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const decrypt = (salt, encoded) => {
    if (encoded) {
      const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
      const applySaltToChar = (code) =>
        textToChars(salt).reduce((a, b) => a ^ b, code);
      return encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("");
    }
  };

  useEffect(() => {
    setEmail(decrypt("email", localStorage.getItem("email")));
    setPassword(decrypt("password", localStorage.getItem("password")));
  }, []);

  return (
    <Formik
      initialValues={{
        email: email,
        password: password,
        appType: getAppMode() === "pro" ? "testRoutePro" : "",
      }}
      enableReinitialize
      onSubmit={props.login}
      validationSchema={ValidationSchema}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-item">
            <Field name="email" type="text" value={values.email}>
              {(props) => (
                <InputComponent
                  {...props}
                  name="email"
                  type="text"
                  placeholder="Email"
                />
              )}
            </Field>
            <ErrorMessage component="span" className="error" name="email" />
          </div>
          <div className="form-item">
            <Field name="password" type="password" value={values.password}>
              {(props) => (
                <InputComponent
                  name="password"
                  {...props}
                  type="password"
                  placeholder="Password"
                />
              )}
            </Field>
            <ErrorMessage component="span" className="error" name="password" />
            <div className="text-right mt-3">
              <Link
                to="/forgot-password"
                onClick={() => {
                  localStorage.removeItem("fullName");
                  localStorage.removeItem("email");
                  localStorage.removeItem("password");
                }}
              >
                Forgot Password?
              </Link>
            </div>
          </div>

          {/* <Field name="appType" type="text" value={values.appType}>
            {(props) => (
              <ToggleButton
                name="appType"
                type="text"
                title={"Login me as a"}
                logo={logo}
                {...props}
              />
            )}
          </Field> */}

          <button
            disabled={props.loading}
            type="submit"
            className="button button--primary button-full-width"
          >
            Login
          </button>
        </form>
      )}
    </Formik>
  );
};

const ValidationSchema = Yup.object({
  email: Yup.string()
    .email("Please enter valid email id")
    .required("Please enter email"),
  password: Yup.string().required("Please enter password"),
});

export default LoginForm;
